@import "@/styles/index.scss";

::-webkit-scrollbar {
    width: 8px;
}

/*定义滚动条 内阴影+圆角*/
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #b3bac4;
}

/*定义滚动条滑动轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
    background: #8f8f90;
}
