@font-face {
  font-family: "iconfont"; /* Project id 4340769 */
  src: url('iconfont.woff2?t=1700616744428') format('woff2'),
       url('iconfont.woff?t=1700616744428') format('woff'),
       url('iconfont.ttf?t=1700616744428') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-dianhua:before {
  content: "\e62d";
}

.icon-dingwei:before {
  content: "\e606";
}

.icon-youbian:before {
  content: "\e624";
}

